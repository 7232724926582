import api from '@/config/api';
import { getSession } from 'next-auth/react';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { Organization } from '@/types/organization';
import { exists } from 'i18next';

// Function to generate a unique nickname
export const generateUniqueNickname = async (): Promise<string> => {
  const prefix = 'Allotment';
  let isUnique = false;
  let nickname = '';

  while (!isUnique) {
    const uniqueId = nanoid(4).toUpperCase(); // Generate a 4-character unique ID
    nickname = `${prefix}-${uniqueId}`;

    // Check if this nickname already exists
    try {
      const response = await api.get(`/organizations/check-nickname/${nickname}`);
      isUnique = !response.data.exists;
    } catch (error) {
      console.error('Error checking nickname uniqueness:', error);
      throw error;
    }
  }

  return nickname;
};

export const createOrGetOrganization = async (name: string): Promise<Organization> => {
  try {
    // const session = await getSession();
    // if (!session || !session.accessToken) {
    //   throw new Error('No active session or access token');
    // }

    // First, try to get an existing organization by name
    try {
      const response = await api.get(`/organizations/by-name/${encodeURIComponent(name)}`);
      // const response = await api.get(`/organizations/by-name/${encodeURIComponent(name)}`, {
      //   headers: {
      //     Authorization: `Bearer ${session.accessToken}`,
      //   },
      // });
      return response.data;
    } catch (error) {
      // If the organization doesn't exist, create a new one
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        const nickname = await generateUniqueNickname();
        const createResponse = await api.post('/organizations', { name, nickname });
        // const createResponse = await api.post('/organizations', { name, nickname }, {
        //   headers: {
        //     Authorization: `Bearer ${session.accessToken}`,
        //   },
        // });
        return createResponse.data;
      } else {
        throw error;
      }
    }
  } catch (error) {
    console.error('Error creating or getting organization:', error);
    throw error;
  }
};

export const fetchOrganizations = async (): Promise<Organization[]> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }
    const response = await api.get<Organization[]>('/organizations', {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteOrganization = async (id: string): Promise<void> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }
    await api.delete(`/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
  } catch (error) {
    console.error('Error deleting organization:', error);
    throw error;
  }
};

export const fetchOrganization = async (id: string): Promise<Organization> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }

    const response = await api.get<Organization>(`/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOrganization = async (id: string, data: Partial<Organization>): Promise<Organization> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
    }

    const response = await api.put<Organization>(`/organizations/${id}`, data, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating organization:', error);
    throw error;
  }
};

export const getOrganizationInfo = async (id: string): Promise<Organization> => {
  try {
    const session = await getSession();
    if (!session || !session.accessToken) {
      throw new Error('No active session or access token');
        }


    const response = await api.get<Organization>(`/organizations/${id}`, {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// check if organization name exists
// check-name/:name
export const checkNameAvailability = async (name: string): Promise<boolean> => {
  try {
    const response = await api.get<{ exists: boolean }>(`/organizations/check-name/${encodeURIComponent(name.trim())}`);
    return !response.data.exists; // Return true if the name doesn't exist (is available)
  } catch (error) {
    throw error;
  }
};
