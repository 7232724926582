"use client";

import { useState, useMemo } from "react";
import { routes } from "@/config/routes";
import TableLayout from "@/app/[lang]/(hydrogen)/tables/table-layout";
import HotelTable from "@/app/shared/hotels/table";
import HotelGrid from "@/app/shared/hotels/hotel-grid";
import { useQuery } from "@tanstack/react-query";
import { getHotels } from "@/app/api/hotels";
import { Button, Switch } from "rizzui";
import { PiPlusBold, PiListBold, PiGridFourBold } from "react-icons/pi";
import Link from "next/link";
import { Hotel } from "@/types/hotel";
import { hasValidPricingFromToday } from "@/utils/hotelUtils";
import { useCurrentUser } from "@/hooks/useCurrentUser";

const pageHeader = {
  title: "Hotels",
  breadcrumb: [
    {
      name: "Hotels",
    },
  ],
};

export default function HotelsPage() {
  const [viewMode, setViewMode] = useState<"list" | "grid">("grid");
  const [showOnlyPriced, setShowOnlyPriced] = useState(true);
  const {
    data: hotelsData,
    isLoading,
    error,
  } = useQuery<Hotel[]>({
    queryKey: ["hotels"],
    queryFn: getHotels,
  });
  const { isSuperAdmin } = useCurrentUser();
  
  const processedHotels = useMemo(() => {
    if (!hotelsData) return [];
    const today = new Date();
    return hotelsData
      .map((hotel): Hotel => ({
        ...hotel,
        id: hotel.id!,
        latitude: hotel.latitude ?? null,
        longitude: hotel.longitude ?? null,
        address: hotel.address ?? null,
        postalCode: hotel.postalCode ?? null,
        city: hotel.city ?? null,
        country: hotel.country ?? null,
        starRating: hotel.starRating ?? null,
        checkInTime: hotel.checkInTime ?? null,
        checkOutTime: hotel.checkOutTime ?? null,
        website: hotel.website ?? null,
        createdAt: "",
        updatedAt: "",
        translations: hotel.translations || [],
        amenities: hotel.amenities || [],
        photos: hotel.photos || [],
        rooms: hotel.rooms || [],
      }))
      .filter(hotel => !showOnlyPriced || hasValidPricingFromToday(hotel, today))
      .sort((a, b) => {
        if (a.isPopular && !b.isPopular) return -1;
        if (!a.isPopular && b.isPopular) return 1;
        return 0;
      });
  }, [hotelsData, showOnlyPriced]);


  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading hotels: {(error as Error).message}</div>;

  return (
    <>
      <TableLayout
        title={pageHeader.title}
        breadcrumb={pageHeader.breadcrumb}
        action={
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Button
                variant={viewMode === "list" ? "solid" : "outline"}
                onClick={() => setViewMode("list")}
              >
                <PiListBold className="h-4 w-4" />
              </Button>
              <Button
                variant={viewMode === "grid" ? "solid" : "outline"}
                onClick={() => setViewMode("grid")}
              >
                <PiGridFourBold className="h-4 w-4" />
              </Button>
            </div>
            {isSuperAdmin && (
              <>
                <div className="flex items-center gap-2">
                  <span>Show only priced hotels:</span>
                  <Switch
                    checked={showOnlyPriced}
                    onChange={() => setShowOnlyPriced(!showOnlyPriced)}
                  />
                </div>
                <Link href={routes.hotels.create}>
                  <Button as="span">
                    <PiPlusBold className="me-1.5 h-[17px] w-[17px]" />
                    Add Hotel
                  </Button>
                </Link>
              </>
            )}
          </div>
        }
      >
        {viewMode === "list" ? (
          <HotelTable data={processedHotels} />
        ) : (
          <HotelGrid data={processedHotels} />
        )}
      </TableLayout>
    </>
  );
}
