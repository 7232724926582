import { Hotel, Room, PricingPeriod } from "@/types/hotel";
import { SearchFilters } from "@/types/search-filters";
import { differenceInDays, getDay } from "date-fns";
import { MealPlan } from "@/types/hotel";

export const hasValidPricing = (hotel: Hotel): boolean => {
  return hotel.rooms.some((room) =>
    room.pricingPeriods.some((period) =>
      Object.values(period.prices).some((mealPlanPrices) =>
        Object.values(mealPlanPrices).some(
          (priceObj) => priceObj.weekday > 0 || priceObj.weekend > 0
        )
      )
    )
  );
};

export function hasValidPricingFromToday(hotel: Hotel, today: Date): boolean {
  if (!hotel.rooms || hotel.rooms.length === 0) return false;

  return hotel.rooms.some((room) => {
    if (!room.pricingPeriods || room.pricingPeriods.length === 0) return false;
    return room.pricingPeriods.some((period) => {
      const startDate = new Date(period.fromDate);
      return (
        startDate >= today &&
        Object.values(period.prices).some((mealPlanPrices) =>
          Object.values(mealPlanPrices).some(
            (priceObj) => priceObj.weekday > 0 || priceObj.weekend > 0
          )
        )
      );
    });
  });
}

export const calculateFinalPrice = (
  basePrice: number,
  hotel: Hotel
): number => {
  if (basePrice <= 0) return 0;

  const vatRate = hotel.vat || 0;
  const municipalityRate = hotel.municipalityTax || 0;
  const allotmentCommissionRate = hotel.allotmentCommission || 0;

  const vat = basePrice * (vatRate / 100);
  const municipalityTax = basePrice * (municipalityRate / 100);
  const priceWithTaxes = basePrice + vat + municipalityTax;
  const allotmentCommission = priceWithTaxes * (allotmentCommissionRate / 100);

  return priceWithTaxes + allotmentCommission;
};

export const formatCurrency = (amount: number | null) => {
  if (amount === null) return "N/A";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "SAR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

interface PricingSummary {
  [organizationId: string]: {
    minPrice: number;
    maxPrice: number;
  };
}

export const calculatePricingSummaries = (
  hotel: Hotel,
  searchFilters: SearchFilters | null
): PricingSummary | null => {

  if (!searchFilters || !searchFilters.dateRange) {
    return null;
  }

  const [fromDate, toDate] = searchFilters.dateRange.map(
    (date) => new Date(date)
  );

  const summary: PricingSummary = {};

  hotel.rooms.forEach((room: Room) => {
    room.pricingPeriods.forEach((period: PricingPeriod) => {
      const periodFromDate = new Date(period.fromDate);
      const periodToDate = new Date(period.toDate);

      if (periodFromDate <= toDate && periodToDate >= fromDate) {
        const organizationId = period.organizationId?.toString() || "default";

        if (!summary[organizationId]) {
          summary[organizationId] = {
            minPrice: Infinity,
            maxPrice: -Infinity,
          };
        }

        Object.entries(period.prices).forEach(([mealPlan, paxPrices]) => {
          if (
            !searchFilters.mealPlans ||
            searchFilters.mealPlans.includes(mealPlan)
          ) {
            Object.entries(paxPrices).forEach(([pax, prices]) => {
              const occupancy = parseInt(pax);
              if (!searchFilters.pax || searchFilters.pax.includes(occupancy)) {
                const weekdayPrice = calculateFinalPrice(prices.weekday, hotel);
                const weekendPrice = calculateFinalPrice(prices.weekend, hotel);


                if (weekdayPrice > 0) {
                  summary[organizationId].minPrice = Math.min(
                    summary[organizationId].minPrice,
                    weekdayPrice
                  );
                  summary[organizationId].maxPrice = Math.max(
                    summary[organizationId].maxPrice,
                    weekdayPrice
                  );
                }
                if (weekendPrice > 0) {
                  summary[organizationId].minPrice = Math.min(
                    summary[organizationId].minPrice,
                    weekendPrice
                  );
                  summary[organizationId].maxPrice = Math.max(
                    summary[organizationId].maxPrice,
                    weekendPrice
                  );
                }
              } else {
              }
            });
          } else {
          }
        });
      } else {
      }
    });
  });

  // Handle cases where no prices were found
  Object.keys(summary).forEach((orgId) => {
    if (summary[orgId].minPrice === Infinity) {
      summary[orgId].minPrice = 0;
    }
    if (summary[orgId].maxPrice === -Infinity) {
      summary[orgId].maxPrice = 0;
    }
  });

  return summary;
};

export const getPriceRange = (
  hotel: Hotel
): { min: number; max: number } | null => {
  let min = Infinity;
  let max = -Infinity;
  let pricesFound = false;
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to the start of today

  hotel.rooms.forEach((room: Room) => {
    room.pricingPeriods.forEach((period: PricingPeriod) => {

      const periodEndDate = new Date(period.toDate);
      if (periodEndDate >= today && period.prices) {
        Object.values(period.prices).forEach((mealPlan: any) => {
          Object.values(mealPlan).forEach((occupancy: any) => {
            const weekdayPrice = occupancy.weekday;
            const weekendPrice = occupancy.weekend;

            if (typeof weekdayPrice === "number" && weekdayPrice > 0) {
              pricesFound = true;
              const finalWeekdayPrice = Math.ceil(
                calculateFinalPrice(weekdayPrice, hotel)
              );
              min = Math.min(min, finalWeekdayPrice);
              max = Math.max(max, finalWeekdayPrice);
            }

            if (typeof weekendPrice === "number" && weekendPrice > 0) {
              pricesFound = true;
              const finalWeekendPrice = Math.ceil(
                calculateFinalPrice(weekendPrice, hotel)
              );
              min = Math.min(min, finalWeekendPrice);
              max = Math.max(max, finalWeekendPrice);
            }
          });
        });
      }
    });
  });

  if (!pricesFound) {
    console.warn("No valid future prices found for hotel:", hotel.id);
    return null;
  }

  // Ensure min and max are not Infinity or -Infinity
  if (min === Infinity || max === -Infinity) {
    console.warn("Price range calculation issue for hotel:", hotel.id);
    return null;
  }

  return { min, max };
};

const isWeekend = (date: Date): boolean => {
  const day = getDay(date);
  return day === 4 || day === 5; // 4 is Thursday, 5 is Friday
};

export const findApplicablePricingPeriods = (
  hotel: Hotel,
  roomId: number,
  startDate: Date,
  endDate: Date
): PricingPeriod[] => {
  const room = hotel.rooms.find(r => r.id === roomId);
  if (!room) {
    return [];
  }

  // Create new Date objects and normalize to start of day
  const searchStart = new Date(startDate);
  const searchEnd = new Date(endDate);
  searchStart.setHours(0, 0, 0, 0);
  searchEnd.setHours(0, 0, 0, 0);

  return room.pricingPeriods
    .filter(period => {
      const periodStart = new Date(period.fromDate);
      const periodEnd = new Date(period.toDate);
      periodStart.setHours(0, 0, 0, 0);
      periodEnd.setHours(0, 0, 0, 0);
      
      // Check if period contains the search dates
      return periodStart <= searchEnd && periodEnd >= searchStart;
    })
    .sort((a, b) => {
      // Sort by fromDate in descending order (newest first)
      return new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime();
    });
};

export const getPriceForDate = (
  date: Date,
  periods: PricingPeriod[],
  mealPlan: MealPlan,
  pax: number,
  hotel: Hotel
): number => {
  // Find the most recent period that contains this date
  // Since periods are sorted by fromDate descending, the first matching period is the newest
  const applicablePeriod = periods.find(period => {
    const periodStart = new Date(period.fromDate);
    const periodEnd = new Date(period.toDate);
    return date >= periodStart && date <= periodEnd;
  });

  if (!applicablePeriod) {
    return 0;
  }

  const prices = applicablePeriod.prices[mealPlan]?.[pax];
  if (!prices) {
    return 0;
  }

  const basePrice = isWeekend(date) 
    ? prices.weekend || prices.weekday 
    : prices.weekday;

  const finalPrice = calculateFinalPrice(basePrice, hotel);
  
  return finalPrice;
};

export const calculateTotalPriceForPeriods = (
  hotel: Hotel,
  roomId: number,
  startDate: Date,
  endDate: Date,
  pax: number,
  mealPlan: MealPlan
): number => {


  const applicablePeriods = findApplicablePricingPeriods(hotel, roomId, startDate, endDate);
  if (applicablePeriods.length === 0) {
    return 0;
  }

  let totalPrice = 0;
  let currentDate = new Date(startDate);
  const endDateTime = new Date(endDate);

  // Normalize dates to start of day
  currentDate.setHours(0, 0, 0, 0);
  endDateTime.setHours(0, 0, 0, 0);


  while (currentDate < endDateTime) {
    const dateKey = currentDate.toISOString().split('T')[0];
    
    // Find the newest period that contains this date
    const periodForDate = applicablePeriods.find(period => {
      const periodStart = new Date(period.fromDate);
      const periodEnd = new Date(period.toDate);
      periodStart.setHours(0, 0, 0, 0);
      periodEnd.setHours(0, 0, 0, 0);
      
      const isWithinPeriod = currentDate >= periodStart && currentDate <= periodEnd;
      if (isWithinPeriod) {

      }
      return isWithinPeriod;
    });

    if (periodForDate) {
      const priceForMealPlan = periodForDate.prices[mealPlan]?.[pax];
      if (priceForMealPlan) {
        const basePrice = isWeekend(currentDate)
          ? priceForMealPlan.weekend || priceForMealPlan.weekday
          : priceForMealPlan.weekday;
        
        const finalPrice = calculateFinalPrice(basePrice, hotel);
        totalPrice += finalPrice;
      } else {
      }
    } else {
    }
    
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return totalPrice;
};
